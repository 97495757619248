import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Box, Grid, InputLabel } from '@mui/material'

import { FeathersError, User, api } from '@shared/api'
import {
  useInputStyles,
  Input,
  InputPhone,
  ButtonBar,
  ButtonBarEnd,
  FieldGroupRow,
  PageLayoutContainer,
  PageLayoutContainerMain,
  SelectComponent as Select,
  Button,
} from '@shared/components'
import { useEnforceLogin, useNotification } from '@shared/hooks'
import { timezoneOptions } from '@shared/utils'

export const pageSizeOptions = [
  { value: 5, label: '5 rows' },
  { value: 10, label: '10 rows' },
  { value: 15, label: '15 rows' },
  { value: 25, label: '25 rows' },
  { value: 50, label: '50 rows' },
]

const editProfileSchema = yup
  .object({
    first_name: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value)),

    last_name: yup.string().required('Last name is a required field'),
    email: yup.string().email().required(),

    cell_phone: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value)),

    ui_prefs: yup
      .object({
        page_size: yup.number().required(),
      })
      .optional(),

    tz: yup.string().required(),
  })
  .required()

const useStyles = tss.withName('EditUserProfile').create(({ theme }) => ({
  label: {
    marginBottom: '.7em',
  },
  buttonContainer: {
    marginRight: '10px',
  },
}))

export type EditProfileData = yup.InferType<typeof editProfileSchema>

export const EditUserProfile: FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setNotification } = useNotification()
  const { user, updateUser } = useEnforceLogin()

  const { classes: inputClasses, cx } = useInputStyles()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm<EditProfileData>({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      cell_phone: user.cell_phone,
      ui_prefs: {
        page_size: user.ui_prefs?.page_size,
      },
      tz: user.tz,
    },
    resolver: yupResolver(editProfileSchema),
    mode: 'onChange',
  })

  const enableSubmit = isValid && isDirty

  const onSubmit: SubmitHandler<EditProfileData> = async (data) => {
    setIsLoading(true)

    const payload: EditProfileData = {
      ...data,
      cell_phone: data.cell_phone
        ? data.cell_phone
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(' ', '')
        : null,
    }

    try {
      const updatedUser = await api.service('users').patch(user.id, payload)
      updateUser({ ...updatedUser, module_access: user.module_access })

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key && key.startsWith('fortis:session:results')) {
          localStorage.removeItem(key)
          i--
        }
      }

      localStorage.setItem('page_size', payload.ui_prefs.page_size.toString())

      goToNormalView()
    } catch (error) {
      // This is handled by the api.on('apiError') event that can be found further below
      // This is just for the app not to break
    } finally {
      setIsLoading(false)
    }
  }

  const formatFieldPropertyName = (fieldPropertyName: string) =>
    fieldPropertyName
      .split('_')
      .map((name) => `${name[0].toUpperCase()}${name.slice(1)}`)
      .join(' ')

  const goToNormalView = () => {
    const [normalViewPath] = window.location.pathname.split('/edit')
    navigate(normalViewPath)
  }

  useEffect(() => {
    const onAPIError = (error: FeathersError) => {
      if (!error.data?.error || typeof error.data?.error === 'undefined') {
        return
      }

      switch (error.data.error.statusCode) {
        case 412:
          error.data.error.meta.details.forEach((errorDetail) => {
            const fieldPropertyName = errorDetail.path[0]

            const message = errorDetail.message.replace(
              `"${fieldPropertyName}"`,
              formatFieldPropertyName(fieldPropertyName)
            )

            setError(fieldPropertyName, { message })
          })

          break
        default:
          setNotification({
            type: 'error',
            label: 'An Error Ocurred. Please Try Again.',
          })
      }
    }

    api.on('apiError', onAPIError)

    return () => {
      api.off('apiError', onAPIError)
    }
  }, [])

  return (
    <form>
      <ButtonBar>
        <ButtonBarEnd>
          <Button
            label={t('common.cancel')}
            color="secondary"
            onClick={goToNormalView}
            containerClassName={classes.buttonContainer}
          />
          <Button
            label={t('common.save')}
            type="submit"
            disabled={!enableSubmit}
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
            containerClassName={classes.buttonContainer}
          />
        </ButtonBarEnd>
      </ButtonBar>

      <PageLayoutContainer>
        <PageLayoutContainerMain isFullWidth>
          <FieldGroupRow spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('common.name-first')}
                    placeholder={t('common.name-first-placeholder')}
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('common.name-last')}
                    placeholder={t('common.name-last-placeholder')}
                    required
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message}
                  />
                )}
              />
            </Grid>
          </FieldGroupRow>

          <FieldGroupRow spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('common.email')}
                    placeholder={t('common.email-placeholder')}
                    required
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="cell_phone"
                control={control}
                render={({ field }) => (
                  <InputPhone
                    {...field}
                    label={t('common.phone-number')}
                    error={!!errors.cell_phone}
                    helperText={errors.cell_phone?.message}
                  />
                )}
              />
            </Grid>
          </FieldGroupRow>

          <FieldGroupRow spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="tz"
                control={control}
                render={({ field }) => (
                  <Box>
                    <InputLabel
                      test-id="select-timezone-label"
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      required
                      className={cx(inputClasses.label, classes.label)}
                      sx={{
                        '.MuiInputLabel-asterisk': {
                          color: '#ff0000',
                        },
                      }}
                    >
                      {t('mfe-user-profile.timezone')}
                    </InputLabel>

                    <Select
                      {...field}
                      options={timezoneOptions}
                      style={{
                        width: '100%',
                        maxWidth: 'unset',
                        border: 'unset',
                        height: '44px',
                      }}
                      error={!!errors.tz}
                      helperText={errors.tz?.message}
                    />
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="ui_prefs.page_size"
                control={control}
                render={({ field }) => (
                  <Box>
                    <InputLabel
                      test-id="select-page-size-label"
                      variant="standard"
                      htmlFor="uncontrolled-native"
                      required
                      className={cx(inputClasses.label, classes.label)}
                      sx={{
                        '.MuiInputLabel-asterisk': {
                          color: '#ff0000',
                        },
                      }}
                    >
                      {t('mfe-user-profile.page-size-results')}
                    </InputLabel>

                    <Select
                      {...field}
                      options={pageSizeOptions}
                      style={{
                        width: '100%',
                        maxWidth: 'unset',
                        border: 'unset',
                        height: '44px',
                      }}
                      error={!!errors.ui_prefs?.page_size}
                      helperText={errors.ui_prefs?.page_size?.message}
                    />
                  </Box>
                )}
              />
            </Grid>
          </FieldGroupRow>
        </PageLayoutContainerMain>
      </PageLayoutContainer>
    </form>
  )
}
